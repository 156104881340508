header {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  padding: 1em;
}

.activator {
  padding: 1em;
  border-radius: 5em 0 0 5em;
  cursor: pointer;

  &:hover, &:focus, &:active, &.active {
    background-color: #a9b19a;
    box-shadow: 5px 5px 5px #1e1a18;
  }  
}
.active {
  border-radius: 5em 0 0 5em;
  background-color: #a16a83;
}
.icon {
  width: 100%;
  max-width: 45px;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav {
  background: transparent;
  border-radius: 5em;
  padding-top: 0.5em;
  clip-path: ellipse(50% 50% at -50% 50%);

  ul {
    display: flex;

    li a {
      display: block;
      padding: 0.5em;
      margin: 0.5em;
      border-radius: 5em 0 0 5em;

      img {
        opacity: 0;
        transform: translateX(-10px);
      }
    }
  }
}