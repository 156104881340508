.card-projects {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding: 5vh;
  max-width: 150vh;
  max-height: 150vh;
  overflow-y: auto;
  gap: 20px;
}
.project-image {
  min-width: 5vh;
  max-width: 45vh;
  max-height: 45vh;
  left: 0;
  flex: 1;
  border-radius: 10px;
}
.projectContent {
  flex: 3;
  color: #000000;
}
.link > a {
  text-decoration: none;
  color: #000000;
}
.project-desc {
  line-height: 30px;
}
