.homePage {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 60%;
}
.profilePic {
  display: flex;
  width: 35%;
}
.profile {
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.welcomeText {
  width: 60%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.name {
  font-size: 13vh;
  color: #52776a;
  font-weight: bolder;
  text-shadow: 4px 4px #000000;
}

.welcomeMessage {
  font-size: 11vh;
  color: #000000;
}

.icons {
  gap: 50px;
  display: flex;
}
.aboutIcon {
  max-height: 70px;
  width: 100%;
}

@media screen and (max-width: 767px) {
  
}


@media screen and (min-width: 768px) and (max-width: 1024px) {
  
}


