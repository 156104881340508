.aboutPage {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.cardAbout {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.display-about {
  color: #000000;
  display: flex;
  flex-direction: column;
  line-height: 30px;
  top: 0;
}
.name {
  font-size: 100px;
  top: 0;
}
.bio {
  font-size: 18px;
  color: #000000;
}
@media screen and (max-width: 767px) {
 
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
 
}
@media screen and (min-width: 1024px) {
 
}
