@import url("https://fonts.googleapis.com/css2?family=Karla&family=Poppins:wght@300&family=Ubuntu&display=swap");
body {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  float: none;
  max-height: 100vh;
  overflow-y: auto;
  min-width: 360px;
  margin: 12vh 0;
  background: linear-gradient(to right, #a9b19a, #eee9e9, #cab1b2);
  font-family: "Ubuntu", "Karla", "Poppins";
  box-sizing: content-box;
}
.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .App {
    display: none;
  }
  .SWMessage {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: larger;
    height: 100%;
    width: 100%;
    margin: 200px 0;
  }
}

@media screen and (min-width: 1200px) {
  body {
    height: 80vh;
  }
  .SWMessage {
    display: none;
  }
}
