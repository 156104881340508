.carousel {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5vh;
}
.carousel_link {
  cursor: pointer;
  z-index: 1;
  transition: background-color 0.3s;
}
.carousel_card {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease-in-out;
}
.carousel_card:hover {
  transform: scale(1.05);
}
.carousel_indicator {
  max-width: 50px;
  max-height: 50px;
}
